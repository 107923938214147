<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>推荐电商平台</span>
            </div>
            <div class="tuijian-list" v-loading="tuijianLoading">
                <div class="tuijian-item" v-for="(item,index) in ggList" :key="index">
                    <div class="tuijian-top">
                        <div class="tuijian-img"><img :src="item.banner_url" alt=""></div>
                        <div class="tuijian-text">
                            <div class="tuijian-p">{{item.name}}</div>
                            <div class="tuijian-p1" v-html="delLabel(item.content)"></div>
                        </div>
                    </div>
                    <div class="tuijian-bottom">
                        <!-- <div class="tuijian-bt" @click="goTo('/dianshang_introduce',{id:item.id,coun_id:item.coun_id})">平台简介</div> -->
                        <div class="tuijian-bt" @click="goTo('/recom',{id:item.id,hzy_id:$route.query.hzy_id})">简介</div>
                        <div class="tuijian-bt" @click="goTo('/enter',{id:item.plate_id,hzy_id:$route.query.hzy_id})">申请入驻</div>
                    </div>
                </div>
            </div>

            <div class="pingtai" v-if="guanggao.length>0">
                <div class="pingtai-text">
                    <div class="pingtai-p">{{guanggao[0].title}}</div>
                    <div class="pingtai-p1">{{guanggao[0].content}}</div>
                </div>
                <div class="pingtai-list">
                    <div class="pingtai-item">
                        <img :src="guanggao[1]?guanggao[1].pic_url:require('../../../assets/images/kjdspt1.png')" alt="">
                        <div class="pingtai-item-p">{{guanggao[1]?guanggao[1].title:'多个平台'}}</div>
                        <div class="pingtai-item-p1">{{guanggao[1]?guanggao[1].content:'全区采购批发平台全区采购批发平台'}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="guanggao[2]?guanggao[2].pic_url:require('../../../assets/images/kjdspt2.png')" alt="">
                        <div class="pingtai-item-p">{{guanggao[2]?guanggao[2].title:'多个平台'}}</div>
                        <div class="pingtai-item-p1">{{guanggao[2]?guanggao[2].content:'全区采购批发平台全区采购批发平台'}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="guanggao[3]?guanggao[3].pic_url:require('../../../assets/images/kjdspt3.png')" alt="">
                        <div class="pingtai-item-p">{{guanggao[3]?guanggao[3].title:'多个平台'}}</div>
                        <div class="pingtai-item-p1">{{guanggao[3]?guanggao[3].content:'全区采购批发平台全区采购批发平台'}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="guanggao[4]?guanggao[4].pic_url:require('../../../assets/images/kjdspt4.png')" alt="">
                        <div class="pingtai-item-p">{{guanggao[4]?guanggao[4].title:'多个平台'}}</div>
                        <div class="pingtai-item-p1">{{guanggao[4]?guanggao[4].content:'全区采购批发平台全区采购批发平台'}}</div>
                    </div>
                    <div class="pingtai-item">
                        <img :src="guanggao[5]?guanggao[5].pic_url:require('../../../assets/images/kjdspt5.png')" alt="">
                        <div class="pingtai-item-p">{{guanggao[5]?guanggao[5].title:'多个平台'}}</div>
                        <div class="pingtai-item-p1">{{guanggao[5]?guanggao[5].content:'全区采购批发平台全区采购批发平台'}}</div>
                    </div>
                </div>
            </div>

            <div class="center-text">
                <p></p>
                <span>各国电商平台</span>
            </div>
            
            <div class="state-wrap">
                <div class="state" v-for="(item,index) in guojiaList" :key="index">
                    <div class="state-list">
                        <div class="state-item-text">{{item.name}}</div>
                        <div class="state-item" v-for="(item1,index1) in item.cs.slice(0,6)" :key="index1"   @click="goTo('/pingtai_list',{p_id:item1.p_id,id:item1.id})">
                            <img :src="item1.banner_url" alt="">
                            <p>{{item1.name}}</p>
                        </div>                      
                    </div>
                    <div class="more"  @click="goTo('/state_pingtai',{key:index})">
                        <p>更多</p>                         
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="kjdspt-bj">
            <img :src="guanggao[6]?guanggao[6].pic_url:require('../../../assets/images/kjdspt-bj1.png')" alt="">
            <!-- <div class="kjdspt-p">
                <div class="kjdspt-p1">{{guanggao[6]?guanggao[6].title:''}}</div>
                <div class="kjdspt-p2">{{guanggao[6]?guanggao[6].content:''}}</div>
            </div> -->
        </div>

        <div class="kuajing-bj">
            <img :src="guanggao[7]?guanggao[7].pic_url:require('../../../assets/images/kjdspt-bj2.png')" alt="">
            <div class="kuajing-p">
                <div class="kuajing-p1">{{guanggao[7]?guanggao[7].title:'服务产品，突破想象'}}</div>
                <div class="kuajing-p2">{{guanggao[7]?guanggao[7].content:'专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！'}}</div>
                <div class="kuajing-bt" @click="goTo('contact_us')">联系我们</div>
            </div>
        </div>



    </div>
</template>

<script>
    import banner from '../../../components/banner.vue';
    import {getplatexts ,getcountrys,getplateAds} from '../../../api/index';
    import CONFIG from "../../../utils/config.js"
    import {mapState, mapMutations, mapActions} from 'vuex'
    export default {
        data() {
            return {
                baseUrl:CONFIG.baseUrl,
                tuijianLoading:false,
                ggList:[],  
                guojiaList:[],
                guanggao:[]                          
            }
        },
        components:{
            banner
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.getplatexts();
            this.getplateAds(); 
            this.getcountrys()           
        },
        methods: {
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:3,
                    seat:0
                }).then((res)=>{
                    // console.log(res)
                    if(res.code==200){
                        this.guanggao=res.data
                    }
                })
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 跳转外链
            goUrl(url){
                window.open(url) 
            },
            // 获取推荐电商平台
            getplatexts(){
                // this.tuijianLoading=true
                getplatexts({
                    plate_id:3,
                    recom:1,
                }).then((res)=>{
                    setTimeout(() => {
                        this.tuijianLoading=false
                    }, 200);
                    console.log(res)
                    if (res.code==200) {
                        this.ggList=res.data.data;
                    }
                })
            },
            //获取国家列表
            getcountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data
                    }
                })
            }
            
        },
        
    }
</script>

<style lang="less" scoped>
    .page{
        overflow: hidden;
    }
    .tuijian-wrap{
        margin: auto;
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                width: 18%;
                margin-right: 2.5%;
                padding: 0 10px;
                border: 1px solid #E8E8E8;
                background: #FFFFFF;
                box-sizing: border-box;
                margin-bottom: 35px;
                .tuijian-top{
                    height: 55px;
                    padding: 20px 0 15px 0;
                    display: flex;
                    border-bottom: 1px solid #E8E8E8;
                    .tuijian-img{
                        width: 28%;flex-shrink: 0;display: flex;align-items: center;
                        img{width: 100%;}
                    }
                    .tuijian-text{
                        width: 68%;
                        margin-left: 4%;
                        .tuijian-p{color: #000000;font-size: 16px;font-weight: bold;margin-bottom: 6px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
                        .tuijian-p1{color: #666666;font-size: 14px;font-weight: 400;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
                    }
                }
                .tuijian-bottom{
                    padding: 15px 0;
                    display: flex;justify-content: space-between;
                    .tuijian-bt{width: 45%;height: 30px;line-height: 30px;text-align: center;background: #F4F6F7;
                        color: #666666;font-size: 14px;font-weight: 400;border-radius: 15px;cursor: pointer;
                        &:hover{background: #0071DB;color: #fff;}
                    }
                }
                &:hover{
                    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                }
                &:nth-child(5n+0){margin-right: 0;}

            }
        }
        .pingtai{
            .pingtai-text{
                margin: 25px auto 45px auto;text-align:center;
                .pingtai-p{color: #333333;font-size: 35px;}
                .pingtai-p1{color:#666666;font-size: 16px;font-weight: 300;margin-top: 15px;}
            }
            .pingtai-list{
                display: flex;justify-content: space-between;
                .pingtai-item{
                    width: 17%;border: 1px solid #F3F3F3;padding: 30px;box-sizing: border-box;text-align: center;background: #F7FAFF;
                    img{width: 67px;height: 67px;margin: auto;}
                    .pingtai-item-p{color: #000000;font-size: 18px;margin: 20px 0;}
                    .pingtai-item-p1{color: #666666;font-size: 14px;}
                }
            }
        }

        .state-wrap{
            margin-top: 10px;overflow: hidden;padding:0 20px;
            .state{
                 margin:35px 0;display: flex;align-items: center;justify-content: space-between;
                .state-list{
                    display: flex;align-items: center;flex: 1;
                    .state-item-text{color: #000;font-size: 16px;font-weight: bold;width: 130px;}
                    .state-item{
                        display: flex;align-items: center;font-size:16px;color:#000;cursor: pointer;
                        img{width: 28px;margin-right: 10px;}
                        p{width: 120px;}
                        &:hover{color: #FF7048;text-decoration:underline;}
                    }
                }
                .more{
                    display: flex;align-items: center;color: #FF7048;flex-shrink: 0;cursor: pointer;font-size: 16px;
                    p{margin: 0 5px 1px 0;}
                }
            }

        }
        
    }

    .kjdspt-bj{
        width: 100%;height: 387px;margin-top:45px;position: relative;
        img{width: 100%;height: 100%;display: flex;}
        .kjdspt-p{
            width: 500px;position: absolute;top:50%;left:50%;transform: translate(-20%,-50%);
            .kjdspt-p1{color: #333333;font-size: 35px;margin-bottom: 20px;}
            .kjdspt-p2{color: #000000;font-size: 18px;font-weight: 300;}
        }
    }
    .kuajing-bj{
        width: 100%;position: relative;
        img{width: 100%;height: auto;}
        .kuajing-p{
            width: 100%;position: absolute;top:100px;text-align:center;
            .kuajing-p1{color: #333333;font-size: 35px;margin-bottom: 10px;}
            .kuajing-p2{color: #000000;font-size: 18px;font-weight: 300;}
            .kuajing-bt{
                width: 141px;height: 36px;background: #0071DB;
                border-radius: 15px;line-height: 36px;text-align: center;
                color: #fff;font-size: 17px;font-weight: 300;
                margin: 30px auto;cursor: pointer;
            }
        }
    }

</style>